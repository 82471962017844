<template>
  <div class="diseaseList">
    <!-- 筛选查询框 @change="selectGoodsByIdBtn"-->
    <el-form :inline="true">
      <el-button
        class="addBtn"
        type="primary"
        size="small"
        v-waves
        @click="handleAdd"
        >新增</el-button
      >
      <!-- <el-button type="primary" size="small" v-waves @click="handleAdd"
        >新增标签</el-button
      > -->
      <!-- <el-form-item label="所属门店:" style="margin:0 20px" size="small">
        <el-select
          v-model="listQuery.store_id"
          placeholder="请选择"
          clearable
          multiple
          collapse-tags
        >
          <div v-for="(item, index) in filterStoreList" :key="index">
            <el-option
              :label="item.store_name"
              :value="item.store_id + ''"
            ></el-option>
          </div>
        </el-select>
      </el-form-item> -->
      <el-form-item label="分类名称: " style="margin-left:20px" size="small">
        <el-input
          v-model="listQuery.categoryName"
          placeholder="请输入分类名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-button
        class="addBtn"
        type="primary"
        @click="getListData"
        size="small"
        v-waves
        >搜索</el-button
      >
    </el-form>
    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="store_id" slot-scope="{ row }">
          <StoreToString :store_id="row.store_id" />
        </template>
        <!-- <template slot="img" slot-scope="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.img"
            :preview-src-list="[row.img]"
          >
          </el-image>
        </template> -->

        <template slot="status" slot-scope="{ row }">
          <el-tag
            type="success"
            effect="dark"
            size="mini"
            v-if="row.status === 0"
          >
            显示
          </el-tag>
          <el-tag type="danger" effect="dark" size="mini" v-else>
            隐藏
          </el-tag>
        </template>
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button
              style="color: #afb86d"
              size="mini"
              type="text"
              @click="handleEdit(row)"
              primary
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>

      <!-- 产品提交表单 -->
      <el-dialog
        :title="inpitTypeName"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="dialogForm" label-width="90px">
          <!-- <el-form-item label="所属门店:" required>
            <el-select
              v-model="dialogForm.store_id"
              placeholder="请选择"
              style="width: 100%;"
              clearable
            >
              <div v-for="(item, index) in filterStoreList" :key="index">
                <el-option
                  :label="item.store_name"
                  :value="item.store_id"
                ></el-option>
              </div>
            </el-select>
          </el-form-item> -->
          <el-form-item label="分类名称" required>
            <el-input v-model="dialogForm.categoryName" clearable></el-input>
          </el-form-item>
          <el-form-item label="是否显示: ">
            <el-select
              v-model="dialogForm.status"
              placeholder="请选择(默认为显示)"
              style="width: 100%;"
              clearable
            >
              <el-option
                v-for="item in statusList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="主图地址">
            <el-input
              v-model="dialogForm.img"
              clearable
              placeholder="点击下方按钮可以快速上传"
            ></el-input>
          </el-form-item> -->
          <!-- <el-upload
          class="upload-demo"
          :action="action"
          :multiple="true"
          :on-success="handleSuccess_cover"
          :file-list="fileList"
          list-type="picture"
          name="formData"
        >
          <el-button size="small" type="primary"
            >点击上传分类主图(1张)</el-button
          >
          <div slot="tip" class="el-upload__tip">
            请上传jpg/png文件，500px X 500px, 会自动覆盖原来的图片
          </div>
        </el-upload> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="dialogFormVisible = false">取 消</el-button>
          <el-button class="addBtn" type="primary" @click="save">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  selectCategory,
  updateCategory,
  deleteCategory,
  addCategory,
} from "@/services/articleManage/articleCategory";
import { IP } from "@/services/config.js";
import ElTable from "@/components/elTable/index.vue";
import StoreToString from "@/components/filters/storeToString.vue";
import listHeader from "./listHeader";
import { mapState } from "vuex";
export default {
  components: {
    ElTable,
    StoreToString,
  },
  created() {
    this.getListData();
  },

  data() {
    return {
      loading: false,
      listQuery: {
        type: 1,
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },

      goodsTitle: "",
      tableData: [],
      // 一级分类 二级分类 分类
      selectCategory: "",
      select_two_id: "",
      statusList: [
        { id: 0, name: "显示" },
        { id: 1, name: "隐藏" },
      ],
      categoryArr_one: [], //遍历一级分类
      parentId: "", //增加二级分类时选择的一级分类id
      isAddCategoryOne: 1, //增加一级分类还是二级分类
      dataList: [],
      goodsArr: [],
      dialogFormVisible: false,
      inpitTypeName: "",
      formLabelWidth: "120px",
      // action: "http://127.0.0.1:6688/uploadFile/add",
      action: IP + "/uploadFile/add",
      fileList: [],
      pageCount: "",
      pageSize: 8,
      dialogForm: { store_id: "" },
      shuf: [],
      cover: [],
      describe: [],
    };
  },
  computed: {
    listHeader,
    ...mapState(["userInfo", "filterStoreList"]),
  },
  watch: {},
  methods: {
    async getListData() {
      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.store_id =
        listQuery.store_id?.join(",") || this.userInfo.store_id;
      this.loading = true;
      const { data, code, count } = await selectCategory(listQuery);
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },

    handleAdd(type) {
      this.dialogFormVisible = true;
      this.inpitTypeName = "新增分类";
      this.fileList = [];
      this.dialogForm = {};
      this.isAddCategoryOne = 1;
    },

    /* 修改 */
    handleEdit(row) {
      this.fileList = [];
      this.dialogFormVisible = true;
      this.inpitTypeName = "修改分类";
      this.dialogForm = JSON.parse(JSON.stringify(row));
      // if (this.dialogForm.store_id) {
      //   this.dialogForm.store_id = this.dialogForm.store_id?.split(",");
      // }
    },

    handleSuccess_cover(e) {
      this.dialogForm.img = e.data;
    },

    /* 保存 */
    save() {
      //必填字段校验
      if (!this.dialogForm.categoryName) {
        this.$message.error("请填写必填字段");
        return;
      }
      if (this.inpitTypeName == "新增分类") {
        addCategory({ ...this.dialogForm }).then((res) => {
          console.log(res);
          if (res.code) {
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.dialogFormVisible = false;
            this.getListData();
          }
        });
      }
      if (this.inpitTypeName == "修改分类") {
        updateCategory(this.dialogForm).then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.dialogFormVisible = false;
            this.getListData();
          }
        });
      }
    },

    /* 删除 */
    handleDelete(row) {
      console.log(row.id);
      this.$confirm(
        `此操作将永久删除'${row.categoryName}'分类吗, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteCategory({ id: row.id }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.diseaseList {
  width: 100%;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  /* max-width: 1405px; */
  margin: 10px auto;
}

.goodsTitle:hover {
  cursor: pointer;
}
.upload-demo {
  margin: 20px;
}
</style>
